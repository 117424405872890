<template>
  <div class="form">
    <div class="form-head flex-c">
      <div class="logo flex-c" data-aos="fade" data-aos-delay="700">
        <img
          :class="$store.state.site.name"
          :src="$store.state.site.logoLogin"
          alt=""
        />
      </div>

      <!-- <a class="customer-btn" href="https://kf888.club/" target="_blank"></a> -->
      <!-- let url = 'http://www.aibet555.club/chat.html' // origin: https://kf888.club
    const customerurl = url + "?userid="+ cacheData.myinfo.id +"&username=" + cacheData.myinfo.username -->
    </div>
    <div v-if="registerStep === 0" class="form-body">
      <div class="form-rows" data-aos="fade" data-aos-delay="700">
        <div v-if="show_translate" class="flags flex-js flex-ac">
          <a
            class="gflag flag nturl"
            href="#"
            onclick="doGTranslate('zh-CN|zh-CN');return false;"
            title="Chinese (Simplified)"
          ><img alt="" src="../assets/images/flag_cn.png"
          /></a>
          <a
            class="gflag flag nturl"
            href="#"
            onclick="doGTranslate('zh-CN|en');return false;"
            title="English"
          ><img alt="" src="../assets/images/flag_en.png"
          /></a>
          <a
            class="gflag flag nturl"
            href="#"
            onclick="doGTranslate('zh-CN|vi');return false;"
            title="Vietnamese"
          ><img alt="" src="../assets/images/flag_vn.png"
          /></a>
          <a
            class="gflag flag nturl"
            href="#"
            onclick="doGTranslate('zh-CN|km');return false;"
            title="Khmer"
          ><img alt="" src="../assets/images/flag_kh.png"
          /></a>
        </div>
        <div class="form-input flex-ac flex-jb">
          <div class="form-label flex-ac">帐号</div>
          <input v-model="user_name" type="text" />
        </div>
        <div class="form-input flex-ac flex-jb">
          <div class="form-label flex-ac">密码</div>
          <input v-model="pwd" type="password" />
        </div>
        <div class="form-input flex-ac flex-jb">
          <input
            v-model="code"
            placeholder="验证码"
            type="text"
            @keyup.enter="login"
          />
          <div class="valid-code" @click="updateValidCode">
            <img :src="valid_code" alt="" />
          </div>
        </div>
        <div class="btns flex-ac flex-jb">
          <!-- <div class="guest-btn flex-c" @click="getGuest">访&nbsp;&nbsp;客</div> -->
          <div class="login-btn flex-c" @click="login">登&nbsp;&nbsp;录</div>
        </div>
        <div v-if="type === 'register'" class="btns flex-ac flex-jb">
          <div class="register-btn flex-c" @click="register">用戶註冊</div>
        </div>
        <!-- <div class="radios flex-ac flex-jb">
          <div class="radio-item">
            记住帐号
          </div>
          <div class="radio-item">
            忘记密码
          </div>
        </div> -->
      </div>
    </div>
    <div v-if="registerStep == 1" class="form-body">
      <div class="form-rows" data-aos="fade" data-aos-delay="700">
        <div v-if="show_translate" class="flags flex-js flex-ac">
          <a
            class="gflag flag nturl"
            href="#"
            onclick="doGTranslate('zh-CN|zh-CN');return false;"
            title="Chinese (Simplified)"
          ><img alt="" src="../assets/images/flag_cn.png"
          /></a>
          <a
            class="gflag flag nturl"
            href="#"
            onclick="doGTranslate('zh-CN|en');return false;"
            title="English"
          ><img alt="" src="../assets/images/flag_en.png"
          /></a>
          <a
            class="gflag flag nturl"
            href="#"
            onclick="doGTranslate('zh-CN|vi');return false;"
            title="Vietnamese"
          ><img alt="" src="../assets/images/flag_vn.png"
          /></a>
          <a
            class="gflag flag nturl"
            href="#"
            onclick="doGTranslate('zh-CN|km');return false;"
            title="Khmer"
          ><img alt="" src="../assets/images/flag_kh.png"
          /></a>
        </div>
        <div class="form-input flex-ac flex-jb">
          <div class="form-label flex-ac">帐号</div>
          <input v-model="user_name" placeholder="數字英文任意" type="text" />
        </div>
        <div class="form-input flex-ac flex-jb">
          <div class="form-label flex-ac">密码</div>
          <input v-model="pwd" placeholder="請輸入密碼" type="password" />
        </div>
        <div class="form-input flex-ac flex-jb">
          <div class="form-label flex-ac">确认密码</div>
          <input
            v-model="check_pwd"
            placeholder="請再次輸入密碼"
            type="password"
          />
        </div>
        <div class="form-input flex-ac flex-jb">
          <select
            v-model="area_prefix"
            class="layui-unselect"
            style="
              display: inline;
              width: 20%;
              height: 0.75rem;
              color: white;
              border: none;
              border-radius: 0.3rem;
              background-color: #636364;
              font-size: 0.35rem;
            "
          >
            <option value="+1">+1</option>
            <option value="+7">+7</option>
            <option value="+60">+60</option>
            <option value="+61">+61</option>
            <option value="+62">+62</option>
            <option value="+63">+63</option>
            <option value="+65">+65</option>
            <option value="+66">+66</option>
            <option value="+81">+81</option>
            <option value="+82">+82</option>
            <option value="+84">+84</option>
            <option selected="selected" value="+86">+86</option>
            <option value="+91">+91</option>
            <option value="+95">+95</option>
            <option value="+850">+850</option>
            <option value="+852">+852</option>
            <option value="+855">+855</option>
            <option value="+856">+856</option>
            <option value="+853">+853</option>
            <option value="+880">+880</option>
            <option value="+886">+886</option>
          </select>
          <input
            v-model="phone_number"
            placeholder="請輸入手機號碼"
            type="text"
          />
        </div>
        <div class="form-input flex-ac flex-jb">
          <input
            v-model="account_code"
            maxlength="10"
            placeholder="輸入手機驗證碼"
            type="text"
          />
          <div
            :class="`login-btn ${acc_curCount > 0 ? 'disabled' : ''} flex-c`"
            @click="sendPhoneSms"
          >
            {{ acc_curCount > 0 ? `${acc_curCount}秒` : '發送驗證碼' }}
          </div>
        </div>
        <div v-if="type == 'register'" class="btns flex-ac flex-jb">
          <div class="register-btn flex-c" @click="submitRegisterByName">
            註冊
          </div>
        </div>
        <div class="hint">已有帐号？点此登录</div>
        <div v-if="type === 'register'" class="btns flex-ac flex-jb">
          <div class="register-btn black flex-c" @click="goLoginPage">登录</div>
        </div>
        <div v-if="type === 'register'" class="btns flex-ac flex-jb">
          <div
            class="register-btn white flex-c"
            @click="downApp('https://new.coinmoor.com/pcnK.html')"
          >
            APP 下载
          </div>
        </div>
        <!-- <div class="radios flex-ac flex-jb">
          <div class="radio-item">
            记住帐号
          </div>
          <div class="radio-item">
            忘记密码
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { post } from '@/axios'
import jsonp from 'jsonp'
import translate from '@/mixins/translate'

export default {
  name: 'auth-form',
  props: ['type'],
  mixins: [translate],
  data() {
    return {
      timestamp: '',
      api_key: 'fe01ac104d4af02857839d1d62b5202d31bdaedc689928f533ff031e',
      user_name: '',
      pwd: '',
      check_pwd: '', // 注册用
      code: '',
      rememberMe: 0,
      token: '',
      memberInvite: '',
      registerStep: 0,
      area_prefix: '+1',
      phone_number: '',
      acc_interValObj: {}, //timer变量，控制时间
      acc_count: 120, //间隔函数，1秒执行
      acc_curCount: 0, //当前剩余秒数
      account_code: ''
    }
  },
  mounted() {
    this.updateValidCode()
    this.getIpJson()
    // console.log(this.$route)

    if (this.type == 'register') {
      this.token = this.$route?.query?.token
      this.channel = this.$route?.query?.channel
      this.memberInvite = this.$route?.query?.memberInvite
    }
  },
  computed: {
    valid_code() {
      if (this.timestamp) {
        return `${window.location.origin}/api/login_code.do?timestamp=${this.timestamp}`
      } else {
        return `${window.location.origin}/api/login_code.do`
      }
    }
  },

  methods: {
    getGuest() {
      post('login_getTouristInfo.do').then(res => {
        if (res.code === 'fail') {
          this.$message.error(res.info, 2)
          this.updateValidCode()
        } else {
          const data = JSON.parse(res.data)
          const { userPwd, userName } = data
          this.user_name = userName
          this.pwd = userPwd
        }
      })
    },
    login() {
      if (!this.user_name || !this.pwd || !this.code) {
        this.$message.error('栏位不能为空', 2)
        return
      }
      // document.cookie = `loginCode=${this.code}`
      const mapStr = JSON.stringify({
        user_name: this.user_name,
        pwd: this.pwd,
        code: this.code,
        rememberMe: this.rememberMe,
        ip_json: this.$store.state.ip_json
      })

      post('login_checkUserCode.do', this.Qs.stringify({ mapStr })).then(
        res => {
          if (res.code === 'fail') {
            this.$message.error(res.info, 2)
            this.updateValidCode()
          } else {
            // loginCode=3486; username=bbtest005; memberid=20211215050406921
            document.cookie = `username=${this.user_name}`
            // console.log(document.cookie)
            // document.cookie = `memberid=${this.code}`
            this.$store.dispatch(
              'logIn',
              this.$cookies.get('username'),
              this.$cookies.get('memberid')
            )
            this.$router.push('/')
          }
        }
      )
    },

    register() {
      const mapStr = JSON.stringify({
        memberInvite: this.memberInvite,
        token: this.token,
        channelId: this.channel
      })

      if (this.token) {
        post('registerByToken.do', this.Qs.stringify({ mapStr })).then(res => {
          if (res.code === 'fail') {
            this.$message.error(res.info, 2)
          } else {
            // loginCode=3486; username=bbtest005; memberid=20211215050406921
            // document.cookie = `memberid=${this.code}`
            this.registerStep = 1
          }
        })
      } else if (this.memberInvite) {
        post('registerMember.do', this.Qs.stringify({ mapStr })).then(res => {
          if (res.code === 'fail') {
            this.$message.error(res.info, 2)
          } else {
            this.registerStep = 1
            // loginCode=3486; username=bbtest005; memberid=20211215050406921
            // document.cookie = `memberid=${this.code}`
            // this.$store.dispatch(
            //   'logIn',
            //   this.$cookies.get('username'),
            //   this.$cookies.get('memberid'),
            // )
            // this.$router.push('/')
            // this.$router.push('/registerMember')
          }
        })
      } else {
        this.$router.push('/login')
      }
    },

    submitRegisterByName() {
      if (this.validRegister()) {
        const randNum = Math.floor(Math.random() * 10000)
        var commission_rate = '1.6' //佣金率-洗码比
        var bp_limit_list = '50' //庄闲限红设定
        var tpair_limit_list = '46' //对子和限红设定
        var max_profit = '10000000' //最大利潤
        const mapStr = JSON.stringify({
          token: this.token,
          channelId: this.channel,
          user_name: this.user_name,
          pwd: this.pwd,
          area_prefix: this.area_prefix,
          phone_number: this.phone_number,
          nickname: '會員' + randNum,
          avatar: '9',
          commission_rate: commission_rate,
          bp_limit_list: bp_limit_list,
          tpair_limit_list: tpair_limit_list,
          max_profit: max_profit,
          account_code: this.account_code
        })

        post('login_accountRegister.do', this.Qs.stringify({ mapStr })).then(
          res => {
            if (!res) {
              this.registerStep = 1
              return
            }
            if (res?.code == 'fail') {
              this.$message.error(res.info, 2)
            } else {
              this.$message.success('注册成功', 2)
              this.$router.push('/')
              // loginCode=3486; username=bbtest005; memberid=20211215050406921
              // document.cookie = `memberid=${this.code}`
              // this.registerStep = 1
            }
          }
        )
      }
    },

    validRegister() {
      if (!this.user_name) {
        this.$message.error('請創建賬戶名稱', 2)
        return false
      }
      if (!this.phone_number) {
        this.$message.error('請設置手機號碼', 2)
        return false
      }
      if (!this.account_code) {
        this.$message.error('請輸入手機驗證碼', 2)
        return false
      }
      if (!this.pwd) {
        this.$message.error('請設置密碼', 2)
        return false
      }
      if (!this.check_pwd) {
        this.$message.error('請確認密碼', 2)
        return false
      }
      if (!/^[\da-z]+$/.test(this.check_pwd)) {
        this.$message.error('密碼僅支持數字或英文', 2)
        return false
      }
      if (this.pwd !== this.check_pwd) {
        this.$message.error('密碼確認不一致', 2)
        return false
      }

      return true
    },

    updateValidCode() {
      this.timestamp = Math.floor(Date.now())
    },

    getIpJson() {
      jsonp(
        `https://api.ipdata.co/?api-key=${this.api_key}&callback=ip_json`,
        null,
        (err, data) => {
          if (err) {
            console.error(err.message)
          } else {
            if (data) {
              this.$store.commit('setIpJson', data)
            }
          }
        }
      )
    },

    sendPhoneSms() {
      if (this.acc_curCount > 0) {
        return
      }
      if (!this.phone_number || !this.area_prefix) {
        this.$message.error('請輸入手機號碼', 2)
      } else {
        const mapStr = JSON.stringify({
          area_prefix: this.area_prefix,
          user_phone: this.phone_number
        })
        this.$message.loading('验证码已寄出', 2)
        post('registerAccPhoneCode.do', this.Qs.stringify({ mapStr })).then(
          res => {
            if (res.code == 'fail') {
              this.$message.error(res.info, 2)
            } else {
              // loginCode=3486; username=bbtest005; memberid=20211215050406921
              // document.cookie = `memberid=${this.code}`
              this.registerChatTimer()
            }
          }
        )
      }
    },

    registerChatTimer() {
      this.acc_curCount = this.acc_count
      this.acc_interValObj = setInterval(
        this.setRegisterChatTimerChatTimer,
        1000
      ) //启动计时器，1秒执行一次
    },

    setRegisterChatTimerChatTimer() {
      if (this.acc_curCount === 1) {
        clearInterval(this.acc_interValObj) //停止计时器
        this.acc_curCount = 0
      } else {
        this.acc_curCount--
      }
    },

    goLoginPage() {
      this.registerStep = 0
    },

    customEvent() {
    },

    downApp(url) {
      window.location.href = url
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

.form {
  width: size(550);
  height: 90%;
  position: absolute;
  padding: 0;
  right: size(70);
  top: 5%;
  background-color: #272124;
  border-radius: 15px;
  overflow: hidden;
}

.form-head {
  width: 100%;
  height: 20%;
  background-color: #1e1a12;
  color: white;
  position: relative;

  .logo {
    height: 50%;

    > img {
      height: 100%;

      &.wanmei {
        width: 80%;
        height: auto;
      }
    }
  }
}

.hint {
  font-size: 16px;
  color: white;
}

.customer-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 65px;
  height: 65px;
  background: url(../assets/images/customer-icon.png);
  background-size: contain;
  cursor: pointer;
}

.flags {
  width: 100%;
  margin-bottom: size(30);

  .flag {
    width: size(30);
    margin-right: size(50);

    img {
      width: 100%;
    }
  }
}

.form-body {
  position: relative;
  // height: 80%;
  padding: size(47) 0;
}

.form-rows {
  width: 80%;
  height: auto;
  background-image: url('../assets/images/form-bg.png');
  background-size: cover;
  margin: 0 auto;
  padding: size(60) size(50) size(50);
}

.form-input {
  width: 100%;
  height: size(50);
  margin-bottom: size(15);
  border-bottom: 2px solid #343133;
  color: white;

  input {
    background: transparent;
    box-shadow: none;
    border: none;
    width: 80%;
    height: 100%;
    color: white;
    font-size: size(20);

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
  }
}

.form-label {
  color: white;
  font-size: size(16);
  width: 20%;
  text-align: left;
  font-size: size(20);
}

.btns {
  margin-top: size(40);
  margin-bottom: size(40);
}

.guest-btn {
  width: size(100);
  height: size(50);
  border-radius: 50px / 50px;
  background-color: #292627;
  color: white;
  font-size: size(20);
}

.login-btn {
  width: 100%;
  height: size(50);
  border-radius: 50px / 50px;
  background-color: #cfa97f;
  color: #333;
  font-size: size(20);
  font-weight: bold;
  cursor: pointer;
  user-select: none;

  &.disabled {
    background-color: #666;
    color: black;
  }
}

.register-btn {
  width: 100%;
  height: size(50);
  border-radius: 50px / 50px;
  background-color: #cfa97f;
  color: #333;
  font-size: size(20);
  font-weight: bold;
  cursor: pointer;

  &.black {
    background-color: #292627;
    color: white;
  }

  &.white {
    background-color: white;
    color: #292627;
  }
}

.valid-code {
  width: size(130);
  height: size(50);
  background: white;

  img {
    width: 100%;
    height: 100%;
  }
}

.radio-item {
  font-size: size(18);
  color: white;
}

a.gflag {
  background-image: none;
}

@include md {
  .form {
    width: 100vw;
    height: 100%;
    position: relative;
    padding: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #272124;
    border-radius: 0;
    overflow: hidden;
    overflow-y: scroll;
  }

  .form-head {
    width: 100%;
    background-color: rgba(30, 26, 18, 0.8);
    color: white;
    padding: 0.5rem 0;
    position: relative;

    > .logo {
      height: 80%;
    }
  }

  .customer-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 45px;
    height: 45px;
    background: url(../assets/images/customer-icon.png);
    background-size: contain;
    cursor: pointer;
  }

  .flags {
    width: 100%;
    margin-bottom: sizem(30);

    .flag {
      width: sizem(40);
      margin-right: sizem(50);
    }
  }
  .form-body {
    position: relative;
    // height: auto;
    background-color: #272124;
    padding: 0;
    margin-top: sizem(47);

    .logo {
      height: 60%;
    }
  }

  .form-rows {
    width: 80%;
    background-image: url('../assets/images/form-bg.png');
    background-size: 100% 100%;
    margin: 0 auto;
    padding: sizem(50) sizem(40) sizem(30) sizem(40);
    overflow-y: auto;
  }

  .form-input {
    width: 100%;
    height: sizem(50);
    margin-bottom: sizem(15);
    border-bottom: 2px solid #343133;
    color: white;

    input {
      background: transparent;
      box-shadow: none;
      border: none;
      width: 70%;
      height: 100%;
      color: white;
      font-size: sizem(20);
    }
  }

  .form-label {
    color: white;
    font-size: sizem(16);
    width: 30%;
    text-align: left;
    font-size: sizem(20);
  }

  .btns {
    margin-top: sizem(30);
    margin-bottom: sizem(20);
  }

  .guest-btn {
    width: sizem(100);
    height: sizem(50);
    border-radius: 50px / 50px;
    background-color: #292627;
    color: white;
    font-size: sizem(20);
  }
  .login-btn {
    width: sizem(120);
    height: sizem(50);
    border-radius: 50px / 50px;
    background-color: #cfa97f;
    color: #333;
    font-size: sizem(20);
    font-weight: bold;
  }

  .register-btn {
    width: 100%;
    height: sizem(50);
    border-radius: 50px / 50px;
    background-color: #cfa97f;
    color: #333;
    font-size: sizem(20);
    font-weight: bold;
  }

  .valid-code {
    width: sizem(130);
    height: sizem(55);
    background: white;
  }

  .radio-item {
    font-size: sizem(18);
    color: white;
  }
}
</style>
